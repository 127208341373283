@use 'src/assets/styles/media'


.container
  z-index: 999
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  overflow-y: hidden
  & *
    -webkit-user-select: none
    -webkit-touch-callout: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

.backdrop
  position: fixed
  z-index: 990
  top: 0
  left: 0
  right: 0
  bottom: 0
  max-width: 100vw
  background: rgba(30, 29, 29, 0.93)

.close_icon
  -webkit-tap-highlight-color: transparent
  z-index: 1000
  color: #eeeaea
  cursor: pointer
  position: fixed
  top: 0
  right: 0
  padding: 20px 30px
  transition: 280ms ease-out
  font-size: 2.6rem
  &:hover
    transform: scale(1.15)

.slider_container
  position: relative
  z-index: 999
  width: 100vw
  height: 100vh

.left_container, .right_container
  -webkit-tap-highlight-color: transparent
  cursor: pointer
  position: absolute
  height: 100vh
  width: 40px
  display: flex
  justify-content: center
  align-items: center

.left_container
  left: 0

.right_container
  right: 0

.nav_icon
  -webkit-tap-highlight-color: transparent
  font-size: 2rem
  color: #eeeaea

.window
  width: 90vw
  position: absolute
  top: 50%
  right: 50%
  transform: translate(50%, -50%)
  margin: 0 auto
  height: 80vh

.slider
  display: flex
  flex-direction: row
  flex-wrap: nowrap
  height: 100%
  & > div
    margin: 0 5vw
  & > div:first-child
    margin-left: 0
  & > div:last-child
    margin-right: 0

.slider_item
  height: 100%
  min-width: 100%
  max-width: 100%
  display: flex
  justify-content: center
  align-items: center

.slider_item_wrapper
  position: relative
  display: flex
  justify-content: center
  align-items: center
  height: 100%
  width: 100%

.img
  width: 100%
  height: 100%
  display: flex
  position: relative
  justify-content: center
  align-items: center
  animation: enter 300ms
  touch-action: none
  & img
    width: 100%
    min-height: 35%
    max-height: 100%
    object-fit: contain
    object-position: center
    touch-action: none
    -webkit-user-select: initial
    -webkit-touch-callout: initial
    -moz-user-select: initial
    -ms-user-select: initial
    user-select: initial

.zoom_in
  animation: zoom_in 300ms
  scale: 1.7
  cursor: zoom-out

.zoom_out
  animation: zoom_out 300ms
  scale: 1
  cursor: zoom-in

.loader_container
  position: absolute
  top: 50%
  right: 50%
  transform: translate(50%, -50%)

.zoom_wrapper
  width: 100%
  height: 100%

@keyframes zoom_in
  0%
    scale: 1
  100%
    scale: 1.7

@keyframes zoom_out
  0%
    scale: 1.7
  100%
    scale: 1

@keyframes enter
  0%
    opacity: 0
  100%
    opacity: 1

@media (min-width: media.$medium)

  .right_container
    padding-right: 15px

  .left_container
    padding-left: 15px

  .slider
    & > div
      margin: 0 10vw

  .window
    height: 75vh
    width: 80vw

@media (min-width: media.$large)
  .window
    height: 90vh

  .img img
    min-height: initial
    width: initial
    min-width: 60%
    max-width: 100%

  .slider
    & > div
      margin: 0 5vw

  .window
    height: 75vh
    width: 90vw

@media (min-width: media.$extLarge)
  .window
    height: 88vh