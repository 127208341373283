@use 'src/assets/styles/media'


.container
  width: 100%
  display: flex
  flex-direction: column

.title
  margin-bottom: 60px
  font-size: 2.2rem
  color: #111

.list
  width: 100%
  & > div
    height: 60vh
  & > div:not(:first-child)
    margin-top: 20px

@media (min-width: 770px)
  .title
    font-size: 3.5rem
    margin-bottom: 80px

  .list
    display: flex
    flex-direction: row
    flex-wrap: wrap
    & > div
      height: 80vh
      width: calc(50% - 25px / 2)
      margin: 0
    & > div:not(:first-child)
      margin-top: 0
    & > div:nth-child(odd)
      margin-right: 25px
    & > div:nth-child(n + 3)
      margin-top: 20px

@media (min-width: media.$large)
  .list
    & > div
      height: 80vh
      width: calc(50% - 50px / 2)
      margin: 0
    & > div:not(:first-child)
      margin-top: 0
    & > div:nth-child(odd)
      margin-right: 50px
    & > div:nth-child(n + 3)
      margin-top: 20px
