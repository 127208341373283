@font-face
  font-family: 'Custom Light'
  src: local('MyFont'), url('../fonts/Poppins-Light.ttf') format('ttf')

@font-face
  font-family: 'Custrom Regular'
  src: local('MyFont'), url('../fonts/Poppins-Regular.ttf') format('ttf')

@font-face 
  font-family: 'Custom Medium'
  src: local('MyFont'), url('../fonts/Poppins-Medium.ttf') format('ttf')

@font-face 
  font-family: 'Custom Bold'
  src: local('MyFont'), url('../fonts/Poppins-Bold.ttf') format('ttf')

@font-face
  font-family: 'Custom ExtBold'
  src: local('MyFont'), url('../fonts/Poppins-ExtraBold.ttf') format('ttf')