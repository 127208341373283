.container
  position: fixed
  bottom: 0
  right: 0
  margin: 30px 35px
  cursor: pointer
  padding: 15px 18px
  border-radius: 50%
  z-index: 1
  background: rgba(55, 54, 54, 0.3)
  transition: 350ms
  color: white
  &:hover
    background: rgba(55, 54, 54, 1)