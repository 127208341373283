@use '../../assets/styles/colors'
@use '../../assets/styles/media'


.intro
  margin-top: 40vh
  margin-bottom: 50px

.name
  color: colors.$main_gray
  letter-spacing: 5px
  font-family: "Custom Light", sans-serif
  font-weight: 300
  text-transform: uppercase
  margin-bottom: 0

.position
  font-weight: 700
  margin-top: 10px

.description
  color: colors.$main_gray
  line-height: 145%
  font-size: 1.15rem
  & a
    color: #333
    font-size: 1.2rem
    outline: none
    font-weight: 600

.links
  color: colors.$main_gray
  line-height: 145%
  font-size: 1.15rem
  & a
    color: #333
    font-size: 1.2rem
    outline: none
    font-weight: 600

.hr
  margin: 50px 0 20px
  height: 1px
  width: 100%
  background: colors.$light_gray
  border: none

.arrow_down
  cursor: pointer
  position: absolute
  fill: #4e4e50
  top: 620px
  right: 52%
  scale: 1.7

@media (min-width: media.$small)
  .intro
    margin-top: 30px
    margin-right: 40vw
    min-height: 250px

@media (min-width: 770px)
  .intro
    min-height: 450px
    margin-top: 50px
    margin-bottom: 30px

  .name
    margin-bottom: 10px

  .position
    font-size: 3.3rem

  .description
    font-size: 1.4rem
    line-height: 130%

@media (min-width: media.$medium)
  .position
    font-size: 3.8rem
    line-height: 110%

  .intro
    margin-top: 200px
    margin-bottom: 100px
    height: fit-content
    min-height: 100px
    width: 60vw

@media (min-width: media.$large)
  .intro
    height: 450px
    margin-top: 120px
    margin-bottom: 20px

  .links a
    margin: 0 7px
    display: inline-block
    cursor: pointer
    transition: 300ms
    &:hover
      transform: scale(1.08)
    &:first-child
      margin-left: 3px

@media (min-width: media.$large)
  .intro
    width: 50vw

@media (min-width: media.$extLarge)
  .intro
    margin-bottom: 0