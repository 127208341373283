@use '../../../assets/styles/colors'

.container
  position: fixed
  bottom: 0
  right: 0
  width: 100vw
  height: 0
  background: colors.$main_back
  z-index: 20

.open
  animation: open_curtain 450ms cubic-bezier(.3,0,.5,1) forwards

@keyframes open_curtain
  0%
    height: 0
  100%
    height: 100vh