*, *::after, *::before
  box-sizing: border-box
  -webkit-tap-highlight-color: transparent


html, body
  font-family: "Custom Regular", sans-serif
  letter-spacing: normal
  padding: 0
  margin: 0
  box-sizing: border-box

html
  -ms-overflow-style: none
  scrollbar-width: none
  overflow-y: scroll
  scroll-behavior: smooth
  overflow-x: hidden
  -webkit-overflow-scrolling: touch
  &::-webkit-scrollbar
    display: none

hr
  border: none

a
  color: inherit
  text-decoration: none
  outline: none

@media (prefers-color-scheme: light)
  html
    color-scheme: light

  body
    color: #111
    background: white