.container
  width: 100%

.section
  display: flex
  flex-direction: column
  width: 100%

.list
  max-width: 700px
  display: flex
  flex-direction: column
  width: 100%
  & > div:not(:first-child)
    margin-top: 15px

.projects_list
  display: flex
  flex-direction: row
  flex-wrap: wrap
  & > div
    margin-top: 15px
    margin-right: 15px

.project_item
  cursor: pointer
  font-size: 1rem
  height: 80px
  width: fit-content
  border-radius: 5px
  padding: 10px
  border: 1px solid #d5d3d3

.img_input
  cursor: pointer
  border: 1px solid #dfdada
  padding: 8px 15px

.main_img_container
  display: flex
  justify-content: center
  align-items: center
  position: relative
  width: 150px
  height: 200px
  & img
    height: 100%
    width: 100%
    object-fit: contain
    object-position: center
