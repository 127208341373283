@use '../../../assets/styles/media'
@use '../../../assets/styles/colors'


$animation_time: 400ms forwards

.container
  position: fixed
  z-index: 200
  height: 85px
  width: 100%
  top: 0
  left: 0

.close_menu_opacity
  opacity: 0
  transition: 300ms

.close_menu_opacity_delay
  opacity: 0
  transition: opacity 80ms linear 320ms

.curtain
  color: colors.$main_gray
  fill: colors.$main_gray

.no_curtain
  color: colors.$main_back
  fill: colors.$main_back

.wrapper
  position: fixed
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%
  height: 82px
  padding: 20px 14px

.logo_wrapper
  position: relative
  cursor: pointer
  display: flex
  flex-direction: row
  align-items: center
  width: 198px
  transition-delay: 60ms
  transition-duration: 30ms

.logo_icon
  //position: absolute
  //top: 0
  //left: 0
  display: block
  height: 100%
  margin-right: 10px
  & svg
    height: 100%
    display: block
    width: auto

.logo_title
  //position: absolute
  //top: 50%
  //right: 0
  //transform: translateY(-50%)
  display: block
  height: 75%
  width: fit-content
  & svg
    height: 100%
    display: block

.menu_wrapper
  cursor: pointer
  display: flex
  flex-direction: row
  align-items: center
  transition: 300ms

.menu_text
  text-transform: uppercase
  font-weight: 800
  letter-spacing: 2.8px
  font-family: "Helvetica ExtBold", sans-serif
  font-size: 12px

@media (min-width: 770px)
  .wrapper
    height: 90px
    padding: 30px 30px 15px

  .logo_wrapper
    width: 214px

@media (min-width: media.$large)
  .wrapper
    height: 105px
    padding-top: 40px
    padding-bottom: 16px

  .logo_wrapper
    width: 230px

  .container
    z-index: 10

  .menu_wrapper:hover
    scale: 1.1

  .logo_wrapper
    transition: 280ms
    &:hover
      fill: colors.$main_gray