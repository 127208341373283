$ringRadius: 7rem
$ringSectors: 30
$animDuration: 8s

.preloader
  display: flex
  color: #666e75
  font: 1em Dosis, sans-serif
  line-height: 1.5
  perspective: 40em

.preloader
  animation: tiltSpin $animDuration linear infinite
  flex-direction: column
  justify-content: center
  align-items: center
  margin: auto
  width: 17em
  height: 17em
  &, &__ring
    transform-style: preserve-3d
  &__ring
    animation:
      name: spin
      duration: $animDuration/2
      timing-function: inherit
      iteration-count: inherit
    font-size: 2em
    position: relative
    height: 3rem
    width: 1.5rem
    &:nth-child(even)
      animation-direction: reverse
  &__sector
    font-weight: 600
    position: absolute
    top: 0
    left: 0
    text-align: center
    text-transform: uppercase
    transform: translateZ($ringRadius)
    &, &:empty:before
      display: inline-block
      width: 100%
      height: 100%
    &:empty:before
      background: linear-gradient(transparent 45%,currentColor 45% 55%,transparent 55%)
      content: ""
    @for $s from 2 through $ringSectors
      &:nth-child(#{$s})
        $angle: (360deg / $ringSectors) * ($s - 1)
        transform: rotateY($angle) translateZ($ringRadius)

/* Animations */
@keyframes tiltSpin
  from
    transform: rotateY(0) rotateX(30deg)
  to
    transform: rotateY(1turn) rotateX(30deg)

@keyframes spin
  from
    transform: rotateY(0)
  to
    transform: rotateY(1turn)