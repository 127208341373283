@use 'src/assets/styles/colors'
@use 'src/assets/styles/media'


.title
  margin-bottom: 25px

.subtitle
  margin-top: 0
  color: colors.$main_gray
  font-size: 1.2rem
  font-weight: 400
  line-height: 135%

.hr
  margin: 40px 0
  height: 1px
  width: 100%
  background: colors.$light_gray

.light_title
  margin-top: 0
  font-size: 1.15rem
  font-weight: 300
  letter-spacing: 2.5px
  color: colors.$main_gray
  text-transform: uppercase
  margin-bottom: 12px

.address
  margin-left: 5px
  font-weight: 600
  font-size: 1.2rem
  color: #333

.address_container
  margin-top: 55px

.contacts_block
  & ul
    list-style: none
    padding: 0
    margin: 0
    margin-left: 4px

.link_container
  width: 100%
  color: #333
  display: flex
  flex-direction: row
  justify-content: flex-start
  align-items: center

.link_icon
  width: 10%
  margin-right: 25px
  font-size: 1.4rem
  color: darken(colors.$main_gray, 20%)

.link_link
  padding: 7px 0
  font-size: 1.15rem

.contacts_hr
  margin: 4.5px auto
  width: 90%
  height: 1px
  background: colors.$light_gray

.anim_wrapper
  position: absolute
  z-index: -1
  scale: 1.3
  right: 11%
  top: 320px

@media (min-width: media.$small)
  .content
    display: flex
    flex-direction: row
    padding: 0 12px
    & > div
      max-width: 48%
      width: fit-content
    & > div:nth-child(odd)
      margin-right: 45px

  .contacts_hr
    max-width: 300px
    margin: 7px 0 7px 20px

  .address_container
    margin-top: 0

  .container
    padding-top: 30px

  .hr
    margin: 50px auto
    width: 98%

@media (min-width: media.$large)
  .subtitle
    max-width: 900px

  .hr
    max-width: 850px
    margin-right: auto
    margin-left: 7px

  .content > div:nth-child(odd)
    margin-right: 80px