@use '../../../assets/styles/colors'
@use '../../../assets/styles/media'

.container
  position: fixed
  top: 0
  right: 0
  width: 100vw
  height: 100vh
  background: colors.$main_back
  z-index: 20
  transition: 500ms cubic-bezier(.3, 0, .5, 1)

.close
  height: 0
  & .loading
    animation: hide_opacity 100ms forwards
    visibility: hidden

.closed
  transition: none
  height: 0
  & .loading
    visibility: hidden

.title
  position: absolute
  bottom: 0
  left: 0
  margin: 20px 25px
  font-size: 2.2rem
  color: lighten(colors.$main_gray, 30%)
  font-family: "Helvetica Bold", sans-serif
  font-weight: 600

.loading
  position: fixed
  left: 50%
  top: 48%
  transform: translate(-50%, -50%)

@keyframes sk-scaleout
  0%
    transform: scale(0)

  100%
    transform: scale(1)
    opacity: 0

@keyframes hide_opacity
  0%
    opacity: 1
  100%
    opacity: 1


@media (min-width: media.$medium)
  .title
    margin: 40px 50px
    font-size: 3rem