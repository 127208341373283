@use 'src/assets/styles/colors'
@use 'src/assets/styles/media'


.container
  width: 100%

.content
  display: flex
  flex-direction: column

.subtitle
  margin-bottom: 0

.title
  margin-top: 15px
  margin-bottom: 25px
  font-size: 2.3rem

.info_blocks_list
  margin-bottom: 40px

.details_container, .info_blocks_list
  display: flex
  flex-direction: column

.info_title
  font-size: 1rem
  letter-spacing: 2.5px
  margin-bottom: 10px
  font-weight: 500

.info_block
  display: flex
  flex-direction: column
  margin-bottom: 5px

.info_text
  font-size: 1.2rem
  letter-spacing: .5px
  color: black
  font-family: "Custom Medium", sans-serif
  font-weight: 600

.skill_list
  display: flex
  flex-direction: row
  flex-wrap: wrap
  line-height: 180%

.skill_text
  font-size: 1.2rem
  font-weight: 600

.text_divider
  margin: 0 11px
  color: colors.$main_gray
  font-weight: 600
  font-size: 1.2rem

.desc_container
  margin-bottom: 40px
  font-size: 1.15rem
  letter-spacing: .5px
  color: colors.$main_gray

.text
  font-size: 1.2rem
  line-height: 130%

.project_links_container
  display: flex
  flex-direction: column
  margin-bottom: 12px
  & > a
    margin-bottom: 12px

.project_link
  transition: 280ms
  color: #222
  &:hover
    color: rgb(110, 106, 106)

.photos_container
  padding-bottom: 30px
  width: 100%

.list
  width: 100%
  display: flex
  flex-direction: row
  flex-wrap: wrap
  & > div:not(:first-child)
    margin-top: 15px

.photos_item
  width: 100%
  height: 300px
  & img
    height: 100%
    width: 100%
    object-fit: cover
    object-position: center

@media (min-width: 770px)
  .container
    margin-top: 10px
  
  .subtitle
    font-size: 1.2rem

  .title
    margin-top: 10px
    margin-bottom: 40px
  
  .info_title
    font-size: 1.1rem

  .info_text
    font-size: 1.3rem

  .skill_text
    font-size: 1.3rem

  .details_container
    width: 65%

  .desc_container
    width: 70%

  .text, .project_link
    font-size: 1.3rem

  .photos_item
    height: 550px

  .list
    & > div:not(:first-child)
      margin-top: 30px

@media (min-width: 1024px)
  .content
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
    & > div
      width: 47%

  .desc_container
    margin-top: 55px

  .photos_container
    overflow: hidden
    width: 85vw
    margin: 30px auto

  .list
    display: initial

  .list
    display: initial
    width: 100%
    & > div
      cursor: pointer
      display: inline-block
    & > div:not(:first-child)
      margin-top: 2vw
    & > div
      width: 27vw
      height: 18.6vw
      margin-right: 2vw
      margin-top: 2vw
    & > div:nth-child(12n + 1)
      float: left
      width: 56vw
      height: 39.2vw
      margin-right: 2vw
    & > div:nth-child(12n + 8)
      float: right
      width: 56vw
      height: 39.2vw
      margin-left: 2vw
    & > div:nth-child(6n + 2), & > div:nth-child(6n + 3), & > div:nth-child(6n),
    & > div:nth-child(12n + 9),  & > div:nth-child(12n + 7)
      margin-right: 0

@media (min-width: media.$large)
  .title
    margin-bottom: 30px

  .content
    margin: 60px 45px 0 100px
    & > div:first-child
      width: 38%

  .text
    font-size: 1.35rem
    line-height: 140%

  .photos_container
    margin-top: 40px