.container
  width: 100%
  margin-top: 40px

.list
  max-width: 700px
  display: flex
  flex-direction: column
  width: 100%
  & > div:not(:first-child)
    margin-top: 15px

.details_list
  display: flex
  flex-direction: column
  margin-left: 10px
  & > div:not(:first-child)
    margin-top: 15px

.detail_item
  display: flex
  flex-direction: column
  & > div:not(:first-child)
    margin-top: 10px

.photos_list
  width: 100%
  display: flex
  flex-direction: row
  flex-wrap: wrap

.main_img
  display: flex
  flex-direction: column
  & > input
    margin-bottom: 10px

.main_img_container
  border: 1px solid #dfdbdb
  border-radius: 5px
  width: 200px
  height: 100px
  & img
    width: 100%
    height: 100%
    object-position: center
    object-fit: cover

.photo_item
  position: relative
  cursor: pointer
  margin-top: 15px
  margin-right: 15px
  width: 200px
  height: 100px
  border: 1px gray solid
  display: flex
  justify-content: center
  align-items: center
  border-radius: 4px
  & > img
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center

.photo_nav
  cursor: pointer
  position: absolute
  top: 0
  right: 0
  left: 0
  bottom: 0
  z-index: 1
  opacity: 0
  background: rgba(1, 1, 1, .8)
  padding: 20px
  display: flex
  flex-direction: row
  flex-wrap: wrap
  border-radius: 4px
  transition: 350ms
  color: white
  & svg
    font-size: 2rem
    transition: 350ms
  & svg:hover
    scale: 1.1
  &:hover
    opacity: 1