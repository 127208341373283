@use 'src/assets/styles/colors'

.h1
  font-family: "Custom Bold", sans-serif
  font-weight: 700

.light
  text-transform: uppercase
  color: colors.$main_gray
  letter-spacing: 3px
  font-family: "Custom Light", sans-serif
  font-weight: 300