.loading
  $loader_colors: #afb3b4, #64696c, #505659, #494e50, #383f41
  display: flex
  .dot
    position: relative
    width: 1em
    height: 1em
    margin: 0.4em
    border-radius: 50%

    &::before
      position: absolute
      content: ""
      width: 100%
      height: 100%
      background: inherit
      border-radius: inherit
      animation: wave 2s ease-out infinite


    @for $i from 1 through 5
      &:nth-child(#{$i})
        background: nth($loader_colors, $i)

        &::before
          animation-delay: $i * 0.2s

@keyframes wave
  50%, 75%
    transform: scale(2.5)

  80%, 100%
    opacity: 0