@use '../../../assets/styles/colors'
@use '../../../assets/styles/media'

.container
  width: 100%

.wrapper
  cursor: pointer
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  position: relative

.back
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(26, 27, 35, .45)
  transition: 350ms

.img_loader
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  background: colors.$main_back
  top: 0
  right: 0
  left: 0
  bottom: 0
  z-index: 1

.img_container
  display: flex
  flex-wrap: wrap
  position: absolute
  overflow: hidden
  top: 0
  left: 0
  width: 100%
  height: 100%
  & img
    z-index: -1
    transition: 350ms
    width: 100%
    height: 100%
    object-fit: cover
    object-position: center

.content
  z-index: 2
  margin: 30px 20px
  display: flex
  flex-direction: column
  align-items: flex-start
  color: white

.before_title
  color: lighten(colors.$main_gray, 80%)
  font-size: 1rem
  letter-spacing: 2px

.title
  margin-top: 15px

@media (min-width: media.$medium)
  .content
    margin: 40px 30px

@media (min-width: media.$large)
  .wrapper:hover
    & .img_container img
      max-width: 100%
      max-height: 100%
      transform: scale(1.1)
    & .back
      background: rgba(33, 40, 51, 0.8)