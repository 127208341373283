.container
  width: 100%
  & form
    margin: 50px auto 0
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
  & input
    border: 1px solid gray
    border-radius: 2px
    outline: none
    padding: 5px 8px
    background: #fefefe
    font-size: 1rem
    color: black
  & label
    margin: 20px 0 2px
  & button
    cursor: pointer
    margin-top: 15px
    border: none
    outline: none
    font-size: 1rem
    padding: 10px 15px
    color: white
    background: #6988c0
    border-radius: 4px