@use 'src/assets/styles/media'


.container
  position: fixed
  left: 0
  bottom: 0
  margin: 25px 10px
  z-index: 1
  width: 120px
  height: 30px

.back
  cursor: pointer
  top: 0
  left: 0
  padding: 14px
  border-radius: 35px
  position: absolute
  color: #ffffff
  display: flex
  flex-direction: row

.text
  pointer-events: none
  position: absolute
  font-size: .8rem
  left: 40px
  top: 14.5px

@media (min-width: media.$medium)
  .back
    padding: 15px
    font-size: 1rem

  .container
    margin: 45px 35px

  .text
    font-size: .9rem